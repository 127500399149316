// Fonts
$font-family-base:            'Montserrat', sans-serif
$font-weight-lighter:         100
$font-weight-extra-light:     200
$font-weight-light:           300
$font-weight-normal:          400
$font-weight-medium:          500
$font-weight-semibold:        600
$font-weight-bold:            700
$font-weight-bolder:          800

// Headers
$display-font-weight:         $font-weight-semibold

// Icons
$bootstrap-icons-font-file:   '../fonts/bootstrap-icons'

// Colors
$blue:                      #04638f
$teal:                      #edec7c
$cyan:                      #01628F
$cyan-light:                #1EB898
$gray-800:                  #3A3A3A
$gray-900:                  #242424
$primary:                     $blue
$secondary:                   $teal

// Hamburguer
$navbar-light-color:          $cyan

// Radius
$border-radius:               0
$btn-border-radius-sm:        0
$btn-border-radius-lg:        0

// Buttons
$btn-padding-y-sm:            0.5rem
$btn-padding-x-sm:            1rem
$btn-padding-y:               0.75rem
$btn-padding-x:               1.5rem
$btn-padding-y-lg:            1rem
$btn-padding-x-lg:            2rem

// Forms
$form-check-input-width:      0.5em