.loader
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    opacity: 0
    visibility: hidden
    background: rgba(255, 255, 255, 0.85)
    z-index: 9999
    transition: opacity 500ms linear, visibility 0s linear 500ms
    &.is-loading
        opacity: 1
        visibility: visible
    &__logo
        position: fixed
        top: 50%
        left: 50%
        width: 80px
        margin: -40px 0 0 -40px
        animation: pulse 1s infinite
        img
            width: 100%

@keyframes spin
    100%
        transform: rotate(360deg)